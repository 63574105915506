import {NavBar} from "./component/Navbar";
import {Container} from "react-bootstrap";
import {Route, Routes, useLocation} from "react-router-dom";
import {HomePage} from "./pages/HomePage";
import 'bootstrap/dist/css/bootstrap.min.css';
import {DiscordTextConverterPage} from "./pages/DiscordTextConverterPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {MinecraftServerStatusCheckerPage} from "./pages/MinecraftServerStatusCheckerPage";
import {ProjectsPage} from "./pages/ProjectsPage";
import {DetailedProjectPage} from "./pages/DetailedProjectPage";
import {PasswordGenPage} from "./pages/PasswordGenPage";
import {QrCodeGenPage} from "./pages/QrCodeGenPage";
import {ContactPage} from "./pages/ContactPage";
import {AboutMePage} from "./pages/AboutMePage";
import {AdminDashboard} from "./pages/admin/AdminDashboard";
import {LoginPage} from "./pages/admin/LoginPage";

import './css/style.css';
import {McSocketChat} from "./pages/McSocketChat";
import {McRestChat} from "./pages/McRestChat";

function App() {
    const location = useLocation();
    //TITLES PER PATH
    if (location.pathname === "/") {
        document.title = "Chenko - Home"
    } else if (location.pathname === "/projects") {
        document.title = "Chenko - Projects"
    } else if (location.pathname.match("/project.*")) {
        document.title = "Chenko - Project"
    } else if (location.pathname === "/who-am-i") {
        document.title = "Chenko - Who Am I?"
    } else if (location.pathname === "/contact") {
        document.title = "Chenko - Contact Me"
    } else if (location.pathname === "/tools/discord-text-converter") {
        document.title = "Chenko - Convert Text to Discord Emojis"
    } else if (location.pathname === "/tools/mc-status") {
        document.title = "Chenko - Minecraft Server Checker"
    } else if (location.pathname === "/tools/password-gen") {
        document.title = "Chenko - Password Generator"
    } else if (location.pathname === "/tools/qrcode-gen") {
        document.title = "Chenko - QR Code Generator"
    } else if (location.pathname === "/login") {
        document.title = "Chenko - Login"
    } else if (location.pathname === "/dashboard") {
        document.title = "Chenko - Dashboard"
    } else if (location.pathname === "/tools/socket-chat") {
        document.title = "Chenko - Socket Chat"
    } else if (location.pathname === "/McSocketChat.js") {
        document.title = "Chenko - Minecraft REST Chat"
    }
    else {
        document.title = "Chenko - Page Not Found"
    }


    return (
        <>
            <ToastContainer newestOnTop={true} theme={"colored"}/>
            <NavBar/>
            <Container>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/tools/discord-text-converter" element={<DiscordTextConverterPage/>}/>
                    <Route path={"/tools/mc-status"} element={<MinecraftServerStatusCheckerPage/>}/>
                    <Route path={"/tools/password-gen"} element={<PasswordGenPage/>}/>
                    <Route path={"/tools/qrcode-gen"} element={<QrCodeGenPage/>}/>
                    <Route path={"/tools/socket-chat"} element={<McSocketChat/>}/>
                    <Route path={"/tools/rest-mc-chat"} element={<McRestChat/>}/>
                    <Route path={"/who-am-i"} element={<AboutMePage/>}/>
                    <Route path={"/contact"} element={<ContactPage/>}/>
                    <Route path={"/login"} element={<LoginPage/>}/>
                    <Route path={"/dashboard"} element={<AdminDashboard/>}/>
                    <Route path="/projects" element={<ProjectsPage/>}/>
                    <Route path="/project/:project" element={<DetailedProjectPage/>}/>
                    <Route path="*" element={<h1 className={"my-5 text-danger"}>Not Found -404 - Not Found Page Coming
                        Soon</h1>}/>
                </Routes>
            </Container>
        </>
    );
}

export default App;
