import {Alert, Card, Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {useState} from "react";
import {toast} from "react-toastify";
import {checkServer, ipChecker} from "../util/mcServerCheck";
import mcServerChatSockkies from "../util/mcServerChatSockkies";

export function McSocketChat() {
    const [serverIP, setServerIP] = useState("mc.terroflys.com");
    const [serverPort, setServerPort] = useState("25566")

    const [userName, setUserName] = useState("Anonymous")
    const [message, setMessage] = useState("")

    const [showResult, setShowResult] = useState(false)
    const [result, setResult] = useState("")


    return (<>
        <Alert variant={"warning"} style={{fontSize: "1.75rem"}}
               className={"text-center text-black text-capitalize roboto"}>This was made when my minecraft server (for
            friends) was online. <br></br>By the time you are using this it is possible this will no longer
            work.</Alert>

        <Row className={"mt-5"}>
            <Col>
                <h1 className={"display-5 text-light koshgarian"}>Send message directly to my minecraft server <small
                    className={"roboto"} style={{fontSize: "1rem"}}>(or any other sockets)</small></h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card className={"roboto"}>
                    <Card.Header as="h5">Send Message</Card.Header>
                    <Card.Body>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>
                                            Server host
                                        </Form.Label>
                                        <Form.Control spellCheck={false}
                                                      type={"text"}
                                                      className={"mb-3"}
                                                      value={serverIP}
                                                      onChange={(e) => {
                                                          setServerIP(e.target.value)
                                                      }}
                                                      placeholder={"mc.terroflys.com"}/>

                                    </Col>
                                    <Col lg={3}>

                                        <Form.Label>
                                            Server port
                                        </Form.Label>
                                        <Form.Control spellCheck={false}
                                                      type={"number"}
                                                      className={"mb-3"}
                                                      value={serverPort}
                                                      onChange={(e) => {
                                                          setServerPort(e.target.value)
                                                      }}
                                                      placeholder={"25566"}/>

                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>

                                <Form.Label>
                                    Username
                                </Form.Label>
                                <Form.Control spellCheck={false}
                                              type={"text"}
                                              className={"mb-3"}
                                              value={userName}
                                              onChange={(e) => {
                                                  setUserName(e.target.value)
                                              }}
                                              placeholder={"chenko"}/>
                            </Form.Group>
                            <Form.Group>

                                <Form.Label>
                                    Message
                                </Form.Label>
                                <Form.Control spellCheck={true}
                                              type={"text"}
                                              className={"mb-3"}
                                              value={message}
                                              onChange={(e) => {
                                                  setMessage(e.target.value)
                                              }}
                                              placeholder={"Hello There!"}/>
                            </Form.Group>
                        </Form>
                        {showResult &&
                            <Form.Group>
                                <Form.Label>
                                    Result!
                                </Form.Label>
                                <Form.Control spellCheck={false}
                                              as={"textarea"}
                                              rows={7} className={"mb-3"}
                                              value={result} disabled/>
                            </Form.Group>
                        }

                        <Button variant="success" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (ipChecker(serverIP+":"+serverPort)) {
                                mcServerChatSockkies(serverIP, serverPort, userName, message).then((result) => {
                                    toast.success("Message sent!")
                                })
                            } else {
                                toast.error("Invalid IP!")
                            }

                        }}>Send Message</Button>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Made by Chenko | Using: Web sockets, Custom made PaperMC plugin
                            that uses java.net.Socket <small>/server-socket</small>
                        </small>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </>)
}