import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useState} from "react";
import {QRCodeSVG} from "qrcode.react";

export function QrCodeGenPage() {
    const [qrCodeText, setQrCodeText] = useState("");
    const [qrCode, setQrCode] = useState("");

    return <>
        <Row className={"mt-5"}>
            <Col>
                <h1 className={"display-5 text-light koshgarian"}>QR Code Generator</h1>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4} className={"mb-3"}>
                <Card className={"bg-dark text-light"}>
                    <Card.Body>
                        <Card.Title>QR Code Generator</Card.Title>
                        <Card.Text>
                            Generate QR codes for your links, text, and more!
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <Card>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                setQrCode(qrCodeText)
                            }}>
                            <Card.Header>
                                <strong>Enter any text or links you want to convert!</strong>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>

                                        <Form.Group controlId="qrCodeText">
                                            <Form.Label>Text or link to generate</Form.Label>
                                            <Form.Control type="text" placeholder="Enter text or link here"
                                                          value={qrCodeText}
                                                          onChange={(e) => setQrCodeText(e.target.value)}/>
                                        </Form.Group>
                                        <Button className={"mt-2 w-100"} type={"submit"}>Generate QR Code </Button>

                                </Card.Text>
                            </Card.Body></Form>
                        </Card>
                    </Col>
                </Row>
                {qrCode &&
                    <Row className={"mt-5"}>
                        <Col>
                            <Card className={"text-center"}>
                                <Card.Header>
                                    <strong>Your Generated QR Code</strong>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <QRCodeSVG value={qrCode} size={256}
                                                   bgColor={"#ffffff"}
                                                   fgColor={"#000000"}

                                        />
                                    </Card.Text>
                                </Card.Body>
                            </Card>


                        </Col>
                    </Row>}
            </Col>
        </Row>

    </>
}