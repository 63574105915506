import {useEffect, useState} from "react";
import {getAuth} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";
import {SignInWithGoogleButton} from "../../component/ButtonSignInWithGoogle";


export function LoginPage() {
    //Page title

    //navigate from router
    const navigate = useNavigate();
    //Firebase auth
    const auth = getAuth();
    const [user, setUser] = useState(null);
    //Check if user is logged in
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) navigate("/dashboard")
            else setUser(user);
        });
    }, [auth]);
    //Known Issue:
    // Card.text is a <p> tag, which is not allowed to be a child of <p>
    // So we need to wrap it in a <span> tag

    return <>
        <Row className={"mt-5"}>
            <Col>
                <h1 className={"display-5 text-light koshgarian"}>Login</h1>
            </Col>
        </Row>
        <Row className={"mt-3"}>
            <Col>
                <Card className={"bg-dark"}>
                    <Card.Body>
                        <Card.Title className={"text-light koshgarian"}>Login</Card.Title>
                        <Card.Text className={"text-light"}>
                            <span className={"text-light"}>Login with your Google account to access the dashboard.</span>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className={"mt-2"}>
            <Col>
                <SignInWithGoogleButton auth={auth}/>
            </Col>
        </Row>

    </>
}