import {Col, Row} from "react-bootstrap";
import {useState} from "react";
import {InputOutputTextArea} from "../component/InputOutputTextArea";
import {copyUtil} from "../util/copyUtil";

export function DiscordTextConverterPage() {
    const [text, setText] = useState("");
    const [originalText, setOriginalText] = useState("");
    const placeholderText = "Enter text here!";

    function converter(text) {
        let result = "";
        for (let i = 0; i < text.length; i++) {
            let char = text.charAt(i);
            if (char.match(/[a-z]/i)) {
                result += ":regional_indicator_" + char.toLowerCase() + ": ";
            } else if (char.match("!")) {
                result += ":exclamation:"
            } else if (char.match("\\?")) {
                result += ":question:"
            } else if (char.match(/[0-9]/i)) {
                //For numbers
                const numbers = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"]
                result += ":" + numbers[char] + ": "

            } else if (char.match(" ")) {
                result += " "
            } else {
                result += " " + char + " ";
            }
        }
        return result;

    }


    return (<>
        <Row className={"mt-5"}>
            <Col>
                <h1 className={"display-5 text-light koshgarian"}>Discord Text Converter</h1>
            </Col>
        </Row>
        <InputOutputTextArea buttonText={"Copy"} header={"Convert"}
                             placeholderOne={placeholderText} placeholderTwo={converter(placeholderText)}
                             textAreaOneChange={(e) => {
                                 setText(converter(e.target.value))
                                 setOriginalText(e.target.value)
                             }} textAreaTwoValue={text} titleOne={"Text to convert"}
                             titleTwo={"Converted text:"} textAreaTwoDisabled={true}
                             buttonOnClick={(e) => {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 copyUtil(text)
                             }} showButton={true} buttonVariant={"primary"} textAreaOneValue={originalText}
        />
    </>)
}