import {Card} from "react-bootstrap";

export function IntroHomepage(){
    return <>
        <Card className={"nav-bg-dark text-light border-0 text-center"}>
            <Card.Body>
                <h1>Introduction</h1>
                <h4>
                    Welcome to my website! Here you can find some of my projects, and some tools I've made. <br/>
                    This is currently a work in progress, so expect more to come! <br/>
                </h4>
            </Card.Body>

        </Card>

    </>
}