import axios from "axios";

export async function checkServer(address){
    //send ip to firebase DB

    //Using https://mcstatus.io/docs/ to check server status
    const res = await axios.get(`https://api.mcstatus.io/v2/status/java/${address}`).then((response) => {
        return responseToText(response.data);
    }).catch((error) => {
        return error;
    });
    return res;
}
function responseToText(response){
    //Convert response to text
    let text = "";
    if(response.online){
        text += `Server is online!`;
        text += `\nServer IP: ${response.host}:${response.port}`;
        text += `\nVersion: ${response.version.name_clean}`;
        text += `\nPlayers: ${response.players.online}/${response.players.max}`;
        text += `\nMOTD: ${response.motd.clean}`;
    } else {
        text += `Server is offline!`;
    }
    return text;
}

export function ipChecker(address){
    //Check if the address is valid
    //port optional
    //check if ip is ip or domain
    //if it is ip do the following else true
    //const regex = /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))(:[0-9]{1,5})?$/;
    //const regex2 = /^(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,}\.?)|(?:(?:\d{1,3}\.){3}\d{1,3}))(?::\d+)?$/;
    const regex3 = /^(?:(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,}\.?)|(?:(?:\d{1,3}\.){3}\d{1,3}))(?::\d{1,5})?|(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)\.)+(?:[a-zA-Z]{2,}\.?)|(?:(?:\d{1,3}\.){3}\d{1,3})))$/;

    return regex3.test(address);
}
