import PropTypes from "prop-types";

export function HighlightText(props) {
    const {children, highlight} = props
    return <strong className={`text-bg-${highlight} px-1 rounded`}>{children}</strong>
}


// propstypes
HighlightText.propTypes = {
    children: PropTypes.any.isRequired,
    highlight: PropTypes.string
}
HighlightText.defaultProps = {
    highlight: "info"
}