import {io} from "socket.io-client";
import {toast} from "react-toastify";

export default async function mcServerChatSockkies(host, port, userName, message) {
    /*const socket = io(host + ":" + port);
    socket.on("connect", () => {
        socket.send("{ \"username\": \"" + userName + "\", \"message\": \"" + message + "\" }")
    })
    socket.on("connect_error", (data) => {
        console.log(data)
        toast.error("Error connecting to server!")
        //disconnecting, otherwise it will keep trying to connect
        socket.disconnect()
    })*/


    const socket = new WebSocket("ws://" + host + ":" + port);
    socket.onopen = function (event) {
        const payload = {
            username: userName,
            message: message
        };
        const jsonPayload = JSON.stringify(payload);
        socket.send(jsonPayload);
        toast.success("Sent message!");
    };
    socket.onerror = function (event) {
        console.log(event);
        toast.error("Error connecting to server!");
    };





}